export default {
	common: {
		learn: {
			title1: "Primary balance",
			content1: "Richard offers our cash and Hosted Cryptocurrency balance service free of charge, allowing you to store your cash and supported cryptocurrency at no cost. You can transfer crypto from one Richard user's primary balance to another user's primary balance without any charges.\n" +
				"\n" +
				"Network transaction fees\n" +
				"\n" +
				"For transactions on cryptocurrency networks (i.e., transfers of cryptocurrency off the Richard platform), Richard incurs and pays network transaction fees (e.g., miner fees). When you send cryptocurrency from your Richard wallet to another wallet, we will charge a fee based on our estimate of the prevailing network fees for a stand alone wallet-to-wallet send. However, the final fee that Richard pays may differ from the estimated fee due to factors such as batching transactions or changes in network congestion levels. All fees are disclosed at the time of the transaction.\n" +
				"\n" +
				"Efficiency gains and network fees\n" +
				"\n" +
				"Due to potential efficiency gains from batching transactions, the aggregate amount of estimated network fees paid by users within a given batch may exceed the final network fee paid by Richard.\n" +
				"\n" +
				"Lightning Network processing fee\n" +
				"\n" +
				"The Lightning Network is a micropayment network that enables the transfer of bitcoin without incurring the transaction fee associated with the Bitcoin blockchain. When you send bitcoin from Richard via the Lightning Network, we will charge a processing fee equal to 0.1% of the amount of bitcoin transferred.\n" +
				"\n" +
				"Adding cash and cashing out\n" +
				"\n" +
				"When you add cash or cash out, you may be charged a fee depending on the payment method you select. It is important to review the fee details prior to completing the transaction to understand any associated charges.\n" +
				"\n" +
				"Credit transactions\n" +
				"\n" +
				"If you borrow USD from Richard or an affiliate of Richard and we have to sell your BTC collateral (as authorized under an applicable loan agreement), we will charge a flat fee of 2% of the total transaction.",
			title2: "Trading fees and spread",
			content2: "Richard fees\n" +
				"\n" +
				"When you buy, sell, or convert cryptocurrencies on Richard, fees are charged. These fees are calculated at the time you place your order and can be influenced by factors such as your chosen payment method, order size, market conditions, jurisdictional location, and other costs we incur to facilitate your transaction.\n" +
				"\n" +
				"Before submitting your transaction, you can view the fees in the trade preview screen. Please note that fees may vary for similar transactions. By completing an order, you agree to pay the applicable Richard fees for that transaction.\n" +
				"\n" +
				"Spread\n" +
				"\n" +
				"When you place simple buy and sell orders, Richard includes a spread in the quoted price. The spread is also included in the exchange rate when converting from one cryptocurrency to another.\n" +
				"\n" +
				"To see the spread before previewing a buy, sell, or convert order, on the preview screen:\n" +
				"\n" +
				"Buy and sell orders: tap on the tooltip next to your asset's price line item to view the spread.\n" +
				"\n" +
				"Convert orders: tap on the tooltip next to your quoted exchange rate.\n" +
				"\n" +
				"The spread helps increase the likelihood of a successful transaction and allows Richard to lock in your quoted price temporarily while processing your order. Richard may retain any excess spread from a transaction. Please note that the spread may vary for similar transactions.\n" +
				"\n" +
				"Richard Advanced\n" +
				"\n" +
				"No spread is included for Richard Advanced because you're interacting directly with the order book.\n" +
				"\n" +
				"Richard One\n" +
				"\n" +
				"Richard One is a subscription product that offers fee-free buying and selling of cryptocurrencies, with certain limitations. Members may still have spread included in their quoted prices.\n" +
				"\n" +
				"Changes to trading fees and spread\n" +
				"\n" +
				"Richard occasionally tests changes to fees and spread. These changes may be rolled out across different regions, assets, order sizes, and types of trades.\n" +
				"\n" +
				"Richard shows you the total amount, inclusive of spread and fees, each time you preview a trade and afterwards in your transaction history. \n" +
				"\n" +
				"Trade execution\n" +
				"\n" +
				"All trades from your Primary Balance are executed through Richard Exchange's central limit order book or competitive auctions. You can view a market’s order book, bid, ask, and depth in real-time. These order books determine the quoted prices you see during trading.\n" +
				"\n" +
				"Richard does not have any payment for order flow (PFOF) relationships with market makers.",
			title3: "Richard Card",
			content3: "Spending with the Richard Card has no transaction fees. \n" +
				"\n" +
				"Richard does include a spread in the price to buy or sell cryptocurrencies. This allows us to temporarily lock in a price for trade execution while you review the transaction details prior to submitting your transaction.  \n" +
				"\n" +
				"You may be charged fees by an ATM operator. Please refer to your Richard Cardholder Agreement for further details.",
			title4: "Staking services",
			content4: "There is no fee to stake or unstake. Richard takes a commission based on the rewards you receive from the network. Our standard commission is 35% for ADA, ATOM, DOT, MATIC, SOL, and XTZ. For ADA, ATOM, DOT, SOL, and XTZ, the commission is 26.3% for eligible Richard One members. You can find your ETH commission rate in Asset Details. Some users may receive offers for staking promotions with lower rates. The APY and rewards shown in your account always reflects the earnings you receive after our commission. You can find more about commissions in the Richard User Agreement.",
			title5: "Asset recovery",
			content5: "If you sent an unsupported cryptocurrency to your Richard account, it may be eligible for recovery. We’ll charge a network fee for the recovery attempt. For recoveries with an estimated value of over $100, we’ll also charge a 5% recovery fee on the amount over $100. The estimated value of the recovery may differ from the actual market value of the recovery.",
		},
		award1: "Asia's Best Contract for Difference Broker in 2023",
		award2: "2022 Global Best Broker",
		award3: "Most Trusted Broker in 2022",
		award4: "The Most Trusted Brand of 2021",
		award5: "Best Financial Technology Broker",
		award6: "The most transparent broker for transactions",
		analysis: "Analysis",
		health: "Health",
		country: "Country",
		information: "Information",
		help: "Help",
		confirm: "Recognize",
		cancel: "Abolish",
		tip: "prompts",
		place_select: "Please select",
		placeEnter: "Please enter",
		countryCode: "Country code",
		submit: "Submit ",
		more: "More",
		deposit: "Deposit",
		real_trade: "Real Deal",
		paper_trade: "Demo Trading",
		build_paperTrade: "Create a demo account",
		start_trade: "Start Trading Now",
		app_name: "Richard",
		success: "Successes！",
		dataSources_name: "Trading Central",
		reset: "Reprovision",
		iknow: "Got it!",
		noData: "No data available",
		modify: "Edit",
		pingcang: "Close a position",
		all: "Full",
		tip_cardMain: "Take or Upload a photo of the FRONT of your identity card",
		tip_cardBack: "Take or Upload a photo of the BEHIND of your identity card",
		tip_cardMain1: "Take or Upload a photo of the FRONT of your driver's license",
		tip_cardBack1: "Take or Upload a photo of the BEHIND of your driver's license",
		tip_cardMain2: "Take or Upload a photo of your passport",
		tip_cardBack2: "Take or Upload a photo of your passport",
	},
    currency: {
		btcusdt: "Bitcoin",
		ethusdt: "Etherlands",
		ltcusdt: "Litecoin",
		xrpusdt: "Ripple (coin)",
		adausdt: "Ada currency",
		eosusdt: "Pomelo Coin",
		dotusdt: "Boca coin",
		trxusdt: "Wave field coin",
		xmrusdt: "Monroe Coin",
		dogeusdt: "Dogecoin",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "AUD USD",
		fx_sgbpusd: "GBP USD",
		fx_seurusd: "Eurodollar",
		fx_snzdusd: "New Zealand Dollar (NZD) USD",
		fx_susdcad: "USD CAD",
		fx_susdchf: "USD CHF",
		fx_susdjpy: "USD JPY",
		fx_saudcad: "AUD CAD",
		fx_saudchf: "AUD CHF",
		fx_saudjpy: "AUD JPY",
		fx_saudnzd: "AUD New Zealand Dollar (NZD)",
		fx_scadchf: "CAD Swiss Franc",
		fx_scadjpy: "Canadian yen",
		fx_schfjpy: "CHF JPY",
		fx_seuraud: "EUR AUD",
		fx_seurcad: "EUR CAD",
		fx_seurgbp: "Euro Pound",
		fx_seurjpy: "Euro Yen",
		fx_seurnzd: "Euro New Zealand dollar (NZD)",
		fx_sgbpaud: "GBP AUD",
		fx_sgbpchf: "GBP CHF",
		fx_sgbpjpy: "GBP JPY",
		fx_sgbpnzd: "GBP NZD",
		fx_scadnzd: "Canadian and New Zealand dollars",
		hf_CL: "WTI New York Crude Oil",
		hf_OIL: "Brent crude oil",
		hf_GC: "New York gold",
		hf_SI: "New York silver",
		hf_HG: "Copper (US)",
		hf_NG: "U.S. Natural Gas",
		hf_CAD: "Copper (chemistry)",
		hf_AHD: "London Aluminum",
		HX_AAPL: "Pomegranate",
		HX_BABA: "Alibaba, PRC e-commerce company",
		HX_GOOG: "Google",
		HX_WMT: "Wal-Mart, Walmart (retailer)",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "Tesla",
		HX_N225: "Nikkei 225",
		HX_GDAXI: "DAX30, Germany",
		HX_IBEX: "IBEX35, Spain",
		HX_SPX: "S&P 500 index",
		HX_FTSE: "FTSE 100",
		HX_KS11: "KOSPI Korea",
		HX_NZ50: "New Zealand 50",
		HX_PSI20: "Portugal PSI20",
		hf_ZSD: "London Zinc",
		hf_NID: "London Nickel",
		hf_PBD: "London lead",
		hf_SND: "London tin",
		hf_XAU: "London Gold (Spot Gold)",
		hf_XAG: "London Silver (Spot Silver)",
		hf_XPT: "Platinum futures",
		hf_XPD: "Palladium Futures",
		hf_CT: "US Cotton",
		hf_SM: "American soybean meal",
		hf_BO: "U.S. soybean oil",
		HX_SENSEX: "SENSEX, Mumbai, India",
		HX_KLSE: "FTSE Malaysia KLCI",
		HX_KSE100: "Karachi, Pakistan",
		HX_FCHI: "CAC40, France",
		HX_SX5E: "European Snooker 50",
		HX_ICEXI: "ICEX, Iceland",
		HX_ASE: "ASE Athens, Greece",
		HX_OMXC20: "OMX Copenhagen 20",
		HX_OSEBX: "OSEBX, Norway",
		HX_OMXSPI: "OMXSPI, Sweden",
		HX_AMD: "Chaowei Semiconductor",
		HX_DIS: "Disney (name)",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "MasterCard (brand)",
		HX_NVDA: "NVIDIA, computer graphics card company",
		HX_INTC: "Intel",
		HX_GOGO: "Gogo Inc",
		HX_STX: "Seagate Technology",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "AstroCoin",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
	currenctType: {
        title: "Please select a currency",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'Dollar',
        currenctType15: 'Hong Kong currency',
        currenctType16: 'Taiwan currency',
        currenctType17: 'Ringgit',
        currenctType18: 'Singapore dollar',
        currenctType19: 'Yen',
        currenctType20: 'Euro',
        currenctType21: 'Dollar A',
        currenctType22: 'Rupiah',
        currenctType23: 'Pound',
        currenctType24: 'Thai baht',
        currenctType25: 'Dollar',
        currenctType26: 'VND',
        currenctType27: 'Korean won',
        currenctType28: 'MOP',
    },
    addnew2: {
        address: {
			account_type: "Account Type",
			ifsc_code: "IFSC CODE",
            title: "Wallet address",
            account_number: "Account number",
            add_btn: "Add address",
            label_type: "Select type",
            place_type: "Please select type",
            label_number: "Account",
            place_number: "Please enter the account number",
            wallet_address: 'Wallet address',
            bank_name: 'BANK NAME',
            bank_address: "Bank address",
            name: "Name",
            payee_address: 'Beneficiary Address',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Branch Name",
            branch_code: "BRANCH CODE",
            bank_code: "Bank code",
            phone: "Cell-phone number",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Please enter content",
        },
    },
	addnew: {
		paypassTit: "Change withdrawal password",
		paypassTit1: "Please set your withdrawal password",
		paypassTit2: "Initialize your password to your login password.",
		loan: "Loan",
		repaid: "Repaid",
        unpaid: "Unpaid",
		loanMore: "I need a loan.",
		immediateRepayment: "Immediate Repayment",
		accountType1: "Crypto Account",
		accountType2: "Contract account",
		accountType3: "Options accounts",
		from: "Obey ",
        to: "To",
		get: "Will get",
		labelBreed: "Currency",
		placeBreed: "Please select currency",
		labelTransAccount: "Account",
		labelAmount: "Amount",
		placeAmount: "Please enter the transfer amount",
		transferTit:"Transfer",
		convertTit:"Convert",
		balanceTit:"Estimated Balance",
		available: "Available",
        pending: "Close with a latch",
        equivalent: "Be equivalent to",
		coinTrade: "Crypto Trading",
		secondContract: "Second contract",
		number: "Quantities",
		labelNumber: "Quantities",
		placeNumber: "Please enter the number of ransoms",
		time: "Timing",
		loadAmount: "Expected loan amount",
		repaymentCycle: "Loan repayment cycle",
		dailyRate: "Daily rate",
		repaymentMethod: "Repayment method",
		loanTip: "Credit Loan (please make sure the picture is clearly visible)",
		loanTip1: "Upload property certificate",
        loanTip2: "Proof of income (employment)",
        loanTip3: "Details of bank statements",
        loanTip4: "Upload the front photo of your ID card",
        interest: "Interest (desire know about sth)",
		repaymentMethod1: "Due for repayment in a lump sum",
	},
	params: {
		product_foreign: "Forex",
		product_shop: "Commodities",
		product_number: "Indices",
		product_stock: "Stock",
		product_coin: "Crypto",
		billTypes: [
			{v: 101, name: 'Recharge'},
			{v: 102, name: 'Deposit'},
			{v: 103, name: 'Frozen'},
			{v: 104, name: 'Unfreeze'},
			{v: 105, name: 'Deposit'},
			{v: 106, name: 'Deposit'},
			{v: 201, name: 'Withdrawal Freeze'},
			{v: 202, name: 'Withdraw funds'},
			{v: 203, name: 'Withdrawal Successful'},
			{v: 204, name: 'Fail to withdraw funds'},
			{v: 205, name: 'Withdrawal fee'},
			{v: 206, name: 'Transfer out'},
			{v: 207, name: 'Shift to'},
			{v: 208, name: 'Coin Transfer'},
			{v: 209, name: 'Coin Transfer'},
			{v: 301, name: 'Contract Fee'},
			{v: 302, name: 'Contractual income'},
			{v: 303, name: 'Contract loss'},
			{v: 304, name: 'Contract margin'},
			{v: 305, name: 'Contract Margin Return'},
			{v: 311, name: 'Purchase Options'},
			{v: 312, name: 'Options income'},
			{v: 313, name: 'Option Returns'},
			{v: 314, name: 'Option Handling Fee'},
			{v: 315, name: 'Coin Buy Freeze'},
			{v: 316, name: 'Coin Buy Deduction'},
			{v: 317, name: 'Coin Buy Returns'},
			{v: 318, name: 'Coin Buy to Account'},
			{v: 319, name: 'Coin Buy to Account'},
			{v: 320, name: 'Coin Selling Freeze'},
			{v: 321, name: 'Coin Sell Deduction'},
			{v: 322, name: 'Coin Sell Returns'},
			{v: 323, name: 'Coin Sell to Account'},
			{v: 324, name: 'Coin Sell to Account'},
			{v: 325, name: 'Coin Handling Fee'},
			{v: 401, name: 'Miners join'},
			{v: 402, name: 'Miner Returns'},
			{v: 403, name: 'Mining revenue'},
			{v: 404, name: 'Mining Exit'},
			{v: 405, name: 'Mining Exit Fee'},
			{v: 411, name: 'Borrowing income'},
			{v: 412, name: 'Borrowing Fee'},
			{v: 413, name: 'Successful repayment'},
        ],
	},
	message: {
		logout: "Log out",
		register_success: "Successful registration",
		place_inner_personal: "Please enter your personal information first",
		submit_success: "Submitted successfully！",
		copy_success: "Copy Success！",
		copy_error: "Reproduction Failure！",
		modify_success: "Modified successfully",
		no_balance: "Insufficient balance to cover security deposit",
		palce_password: "Please enter your password",
		trade_success: "Successful transaction",
		placeEnterAmount: "Please enter the amount",
		tipTit1: "Are you sure you want to pay for this order?",
	},
	web: {
		account: {
			menu1: "Account Information",
			menu2: "My Assets",
			menu3: "Account Reports",
			menu4: "Account Security",
			menu5: "Notifications",
			menu6: "Activity Centers",
			menu7: "My Wallet",
			menu8: "Withdraw",
			walletAdd: "Add New Wallet",
			report: {
				history_tit: "Transaction History Report",
				stream_tit: "Flow of funds report",
				desc: "Please select the appropriate date range to export the account report. (You will have access to account information for the last 1 year)",
				select_date: "Select date range",
				week: "Last week",
				month: "About a month",
				threeMonth: "Three months",
				sixMonth: "Six months",
				time: "Timing",
				view_report: "Show Report",
				email_send: "Send by e-mail",
				dialog_tit: "Account Reports",
				dialog_p1: "Richard Holding Ltd is registered in the Cayman Islands (SIB number: 1612446) and processes your transactions as a trading entity.",
				tit2: "Mitade Transaction History Report",
				tit4: "Mitade Flow of funds report",
				tit3: "Account Information",
				label1: "Name of the account",
				label2: "Account ID",
				label3: "Account Currency",
				label4: "Reporting time",
				label5: "Reporting period",
				label6: "Cash flow",
				empty: "You have no closed trades",
				email_des: "The report will be sent to your e-mail address",
				tab1: "Reporting",
				tab2: "Daily statement",
				tab3: "Monthly statement",
			},
			account: {
				tit1: "Welcome to Richard",
				tit2: "You can start making deposits and trading in the live environment upon completing your ID verification.",
				p1: "Open Live Account",
				p2: "Identity Verification",
				btn1: "ID Verification",
				tit3: "Personal Information",
				label_name: "Names",
				label_email: "E-mail",
				label_address: "Residential Address",
				tit4: "Account Information",
				label_account: "Account No",
				label_currency: "Base Currency",
				label_company: "Current Service Entity",
				company_tip: "Richard Holding Ltd of Companies is licensed and authorized by the Cayman Islands Monetary Authority (CIMA).",
				tit5: "Open Live Account",
				tit6: "Complete Profile",
				tit7: "Complete your basic information",
				tit8: "Risk Assessment",
				tit9: "Assess your risk-bearing capacity",
				tit10: "Identity Verification",
				tit11: "Complete your ID verification in compliance with regulatory requirements",
				p3: "Please select one of the following supporting documents for ID verification",
				type1: "National ID",
				type2: "Driver Licence",
				type3: "Passport",
				p4: "I confirm that all the information and document provided are complete, true and accurate. I acknowledge for any changes in information provided, I will notify Mitrade within 30 days.",
				next: "Next step",
				btn2: "Certified",
				modify_personal_tit: "Edit Your Information",
			},
			activity: {
				tit: "Activity Centers",
				desc: "You can participate in activities to receive rewards to enhance your investment income",
			},
			capital: {
				real_trade: "Real Deal",
				label_nowAccount: "Current account",
				label_tradeAccount: "Trading Account",
				label_currency: "Base currency",
				deposit_withdraw_record: "Deposit and Withdrawal Records",
				capital_flow_details: "Funds flow details",
			},
			notification: {
				tit: "Notification Settings",
				tit1: "Open notification channels",
				email: "E-mail address",
				message: "Text messaging",
				push: "Push",
				verify: "Validate (a theory)",
				verified: "Validate (a theory)",
				p1: "Please open/verify the following notification channels to keep track of important news!",
				type1: "Marketing",
				tit2: "Notification of marketing activities",
				desc2: "Platform's special information, operation activities and other news",
				tit3: "Operating instructions",
				desc3: "Rich and specialized knowledge material",
			},
			security: {
				tit: "Cryptographic",
				isSet: "Configured",
				set: "Go ahead and set up",
				verify: "Validate (a theory)",
				verified: "Validated",
				tit1: "Safety certification",
				email: "E-mail address",
				phone: "Mobile telephone number",
				phone_tit: "Cell Phone Number Binding",
				password_tit: "Change your password",
			},
		},
		market: {
			hot: "Popular",
			prev: "Preceding page",
			next: "Next page",
			line: "Time-sharing",
			main_subplot: "Master and Deputy Chart Indicators",
			main: "Main chart indicator",
			subplot: "Chart Indicator",
			go_trade: "Skip to transaction page",
		},
		news: {
			tip: "Disclaimer: The above information is general information for reference only and is not to be used as a basis for trading decisions.",
		},
		trade: {
			state1: "Holdings",
			state2: "Pending order",
			state3: "Histories",
			state: "Mode",
			state4: "Closed position",
			state5: "Rescinded",
			type: "Typology",
			type1: "Full",
			type2: "Sell",
			type3: "Buy",
			left_label1: "Self-service",
			left_label2: "Recently viewed",
			left_type1: "Compact Column Mode",
			left_type2: "Loose columnar pattern",
			left_type3: "Show Chart",
			all: "Full",
			right_bp: "Yesterday's harvest",
			right_sp: "Modern day",
			right_height: "Supreme",
			right_low: "Lowest",
			remind_tit: "New Alerts",
			remindList: "Reminder List",
			remind_btn: "New Alerts",
			right_tab1: "Dealings",
			right_tab2: "Particulars",
			right_sell: "Sell",
			right_buy: "Buy",
			right_type: "Typology",
			right_type1: "Market list",
			right_type2: "Pending order",
			right_priceMoney: "Pending order price",
			right_pointerNumber: "Number (lots)",
			right_lever: "Pry bar",
			right_balance: "Available",
			right_stop_profit: "Take profit",
			right_stop_loss: "Stop-loss",
			right_profit_loss: "P/L",
			now_order: "Order Now",
			confirm_order: "Confirmation of order",
			right_tit1: "Trading Strategies",
			right_rinei: "In a few days",
			right_short: "Short-term",
			right_middle: "Mid-term",
			right_time: "Release time",
			right_tit2: "Alternative strategy",
			right_tit3: "Technical Review",
			right_tit4: "Digital",
			right_tit5: "Trading mood",
			right_label_sell: "Seller (of goods)",
			right_label_buy: "Purchaser",
			right_tip: "For information purposes only, not a position of the Division",
			right_tip2: "Updated every 15 minutes",
			right_tit6: "Price variation",
			minute: "Minutes",
			today: "At the present",
			right_tit7: "Price change range",
			now_price: "Current prices",
			right_low_price: "Lowest price",
			right_height_price: "Highest price",
			right_tit8: "Contract Information",
			right_tit9: "AUD/CHF",
			right_label1: "Number of single transactions",
			right_label2: "Maximum leverage",
			right_label3: "Contract size",
			right_label4: "Maximum total number of positions",
			right_label5: "Floating spread",
			right_label6: "overnight charge",
			hand: "Hand",
			right_collection_time: "Collection time",
			right_tip3: "Fee basis: from the overall transaction size",
			right_label7: "Margin ratio",
			right_label8: "Maintenance margin ratio",
			right_label9: "Trading hours",
			right_label10: "Current Trading Session",
			right_label11: "Next trading session",
		},
	},
	home: {
		banner_p1: "",
		banner_p2: "Trade ",
		banner_p3: "Forex, Commodities,",
		banner_p4: "Indices, Shares and More",
		banner_p5: "You can explore the world's hottest financial markets. Richard offers 0 commissions, extremely advantageous low spreads, and flexible leverage configurations.",
		hot: "Popular",
		tit1: "Friendlier transactions",
		p1: "Simple and intuitive interface for easy operation",
		p2: "Support web, mobile APP and desktop, trading at your fingertips",
		p3: "Risk management tools such as stop loss/trailing stop",
		p4: "Multiple technical charts and financial calendars, real-time news",
		p5: "Real-time email, SMS and push notifications",
		p6: "Continuous improvement for a better trading experience",
		btn1: "Explore our platform",
		row3_tit: "Trade freely anytime, anywhere",
		row3_tip: "Support web, mobile APP and desktop, trading at your fingertips",
		row3_tit2: "Scan the code to download",
		row3_tit3: "Desktop",
		row3_tit4: "Mobile",
		regular_tit: "Trusted International Platform",
		regular_tip: "We are committed to providing a safe and responsible trading environment for our customers.",
		regular_tit1: "Regulated by an authoritative body",
		regular_p1: "Industry authority regulation and authorization, win the trust of global customers",
		regular_tit2: "Protecting the security of funds",
		regular_p2: "Retail customer deposits are segregated in trust accounts on an as-needed basis in accordance with regulatory requirements",
		regular_tit3: "Negative balance protection",
		regular_p3: "Provide you with account negative balance protection, negative balance in a timely manner to clear the zero, so that your loss does not exceed the amount of entry, peace of mind trading",
		regular_tit4: "5*24 hours customer service",
		regular_p4: "Sincere and professional customer service team, 24 hours online support, happy to solve any problem you have!",
		service_tit: "More services to help you",
		service_tit1: "Trading Strategies",
		service_p1: "Real-time trading strategies to help you understand the latest market trends, better grasp the timing of trading.",
		service_tit2: "Trading Knowledge",
		service_p2: "Learn to trade for free with Richard and improve your trading skills.",
		service_tit3: "Risk management",
		service_p3: "Learn about the free risk management tools Richard offers to better protect your assets.",
		step_tit: "Easy and convenient to open an account",
		step_tip: "Three simple steps to open an account in minutes",
		step_tit1: "Enrollment",
		step_p1: "Fill out the information and submit your application",
		step_tit2: "Deposit",
		step_p2: "Fast deposit of funds through a variety of methods",
		step_tit3: "Commencement of trading",
		step_p3: "Discover trading opportunities and place orders quickly",
		award_tit: "Our Achievements",
		award_p1: "We have always pursued excellence, striving for excellence, and are committed to providing our clients with quality transaction services.",
		award_p2: "The Richard brand is honored to receive an award from a prestigious organization in the industry, recognizing the team's constant efforts and commitment to its clients.",
		row7_tit: "Real-time global financial information",
		row7_tit1: "Real-time news",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "Click to download",
		p1: "Trade anytime, anywhere",
		p2: "Trade faster and smarter",
		p3: "0 commission trading",
		p4: "The world's hottest investment",
		renderDom1: "Foreign (currency) exchange",
		renderDom2: "US stock market",
		renderDom3: "Platinum",
		renderDom4: "Crude oil",
		renderDom5: "Indices",
		renderDom6: "Cryptocurrency",
		login: "Log In",
		elail: "Email",
		phone: "Phone number",
		no_account: "No account？",
		go_register: "Go to register",
		register: "Enrollment",
		now_register: "Register Now",
		have_account: "Already have an account？",
		go_login: "Go ahead and log in.",
	},
	about: {
		serviceFee: "Service charge",
		introduction: "Platform Introduction",
		trade_platform: "Trading platform",
		complaint_process: "Complaints process",
		page: "Web page",
		place_search: "Search for what you want to know",
		no_answer: "Can't find the answer you need?",
		contact_us: "Contact Us",
		awards: {
			h1: "Awards and Honors",
			p1: "In our quest for excellence and dedication to providing our clients with quality trading services, the Richard brand is honored to receive an award from a prestigious organization in the industry, recognizing the team's continuous efforts and commitment to our clients.",
			p2: "Or experience our award-winning online trading platform immediately!"
		},
		charges: {
			h1: "Fees and charges",
			p1: "As part of our efforts to enhance the customer experience, Richard wants to provide our customers with a more cost-effective trading service. We are committed to being open and transparent about our fees and charges, with no hidden fees. The main transaction costs for our clients are the bid/ask spread and overnight position interest.",
			h2: "First of all, when you trade with Richard you can enjoy",
			li1: "Instant Product Quote",
			li2: "Specialized technical indicators",
			li3: "Exclusive Market Analysis",
			free: "Free (of charge)",
			view: "Look up",
			h3: "Bid-ask spread",
			p2: "Richard charges a bid/ask spread as compensation for its services. This fee is reflected in the product quote and you actually pay the spread when you open a position. Since the spread fee is not fixed, we recommend that you go to the specific product quote page, or log in to the",
			h4: "Deposit and Withdrawal Charges",
			p3: "In order to minimize costs for our customers, we do not charge you for accessing your funds in most cases. However, a third party company or bank may charge you a fee for making a deposit or withdrawal, such as a transit bank fee.",
			p4: "You can ask our customer service specialists for information about the charges that need to be made.",
			p5: "E.g. through international credit card transactions, bank account transfers in/out or transactions in unsupported currencies (Forex conversion), etc.",
			h5: "Overnight position costs",
			p6: "If you are still holding a position at 10pm GMT (22:00 GMT), you will be charged/deposited an overnight fee. Inquiries regarding overnight charges for each product can be made on the specific product quote page, or by logging on to the",
			h6: "Other expenses",
			p7: "No other fees. Our fee policy is completely transparent and any items that require a fee will be announced and communicated in advance, so there is no need to worry about hidden fees."
		},
		contact: {
			h1: "Contact Us",
			address: "Address",
			custom_email: "Client Contact Email",
			business_email: "Business Cooperation Email",
			h2: "Write to us.",
			p1: "If you have any questions about our services, please fill out the form below to submit your question and one of our customer service specialists will respond as soon as possible. In addition, to sign up for the daily trading strategies provided by our partnership with Trading Central, please select 'Subscribe to Daily Market Analysis' in the type of the form below and fill in the language version you would like to receive and we will register you. *Subscription service is only available to existing Richard real clients.",
			h3: "Representation procedures",
			p2: "All complaints we will have a specialized team for you to investigate and resolve, details can be referred to",
		},
		faq: {
			h1: "Common problems",
			tit1: "What's the point of a demo account？",
			tit2: "How to open a real account？",
			tit3: "How to create a market order？",
			tit4: "Why can't I place an order?？",
			p1: "Demo accounts are mostly the same as real accounts in terms of trading interface, data, and operations. Demo accounts contain $50,000 in virtual funds, and are designed to allow clients to familiarize themselves with the platform's features through simulated operations without risking their funds.",
			p2: "To open a live account, please follow the steps below:",
			p3: "1. Enter the Richard registration page and follow the instructions: register your email/mobile phone number, set your login password and submit the confirmation to automatically create a [Demo Account].",
			p4: "2. On the Demo Account page, select 'Switch to Real Account' and follow the procedure to open a 'Real Account'.",
			p5: "Before you open a live account, we recommend that you read and understand the legal disclosures associated with trading.",
			p6: "Please note that you can only deposit to your live account after completing the identity verification.",
			p7: "Click on 'Trade' (Trade) will display all the products available for trading on the platform, you can enter the code or name of the product in the input box on the right to search for it, select a product and click on 'SELL/BUY' (SELL/BUY) to bring up the trading window, in which you can see the current price and the estimated margin required. You can manually adjust the number of trades to be opened, set a stop loss and take profit for risk control, and then click on 'SELL/BUY' to complete the opening of the position. Please note that the prices of all financial products fluctuate and update with the market at any time, and the market may have changed before you press the BUY/SELL button.",
			p8: "First of all, please check whether the Internet connection is normal. When trading varieties of markets temporarily closed quotes or closed markets can cause the inability to place orders. For details, please contact our online customer service for further assistance.",

		},
		fundSecurity: {
			h1: "Financial security",
			p1: "Richard is authorized and regulated by authoritative regulatory bodies. Compliance is at the heart of everything we do and our culture. The safety of your funds is paramount and all client deposits are kept in segregated locations.",
			p2: "Retail customer deposits are held separately in trust accounts as necessary in accordance with regulatory requirements",
			p3: "Richard does not use client funds for any commercial activities other than those permitted by laws and regulations.",
			p4: "Richard will not speculate in any marketplace",
			p5: "Independent audit by a third-party accounting firm",
		},
		help: {
			h1: "Welcome to the Richard Help Center!",
		},
		media: {
			h1: "Media Center",
			p1: "View information, press releases, media features, announcements and other information related to the Richard brand.",
		},
		mitrade: {
			h1: "About Richard",
			view_certificate: "(Methods of identification)",
			p1: "Richard is a financial technology company authorized and regulated by authoritative institutions, focusing on providing investors with a simple and convenient trading experience. The innovative Richard diversified trading platform has repeatedly won awards such as Best Mobile Trading Platform and Most Innovative Brokerage.",
			p2: "Through the Richard platform, you can invest and trade in a wider range of international financial markets, covering stocks, indices, commodities, foreign exchange and hundreds of other popular varieties. Not only limited to the user-friendly mobile and web-based trading platforms, Richard also provides you with competitive trading costs, fast trade execution, excellent customer service and a wealth of information support, helping you to seize timely investment and trading opportunities.",
			p3: "Richard is strictly regulated by the industry authority and multiple agencies.Richard International is authorized and regulated by the Financial Services Commission (FSC) of Mauritius under license number GB20025791.For details of the Mauritius license, you can go to the official website of the FSC at https://www .fscmauritius.org",
			p4: "MRichard Global is licensed by the Australian Securities and Investments Commission (ASIC) under the Financial Services License (AFSL 398528). For more information on Australian licenses, please visit the ASIC website at www.asic.gov.au.",
			p5: "Itrade Holding is authorized and regulated by the Cayman Islands Monetary Authority (CIMA) under SIB License No. 1612446. For more information on the Caymanian License, please visit the official CIMA website at www.cima.ky.",
			p6: "All business operations of Richard are conducted under strict supervision and in compliance with all regulations.",
			h2: "Background of Richard",
			p7: "Richard was founded in Melbourne, Australia by a team of experienced and knowledgeable financial trading and fintech professionals with a vision to make trading easier and more user-friendly!",
			p8: "Our holistic strategy and business model enable us to have a global and in-depth observation and knowledge of the industry development and market demand, so that we can allocate resources in a more targeted manner, continuously innovate technology and optimize efficiency, and continue to bring our customers a more convenient and friendly trading experience. ",
			h3: "Why Richard",
			why1: "Low-threshold trading conditions",
			why2: "Regulated by industry authorities",
			why3: "Simple and intuitive trading platform",
			why4: "High level of online support",
			why5: "Competitive transaction costs",
			why6: "Negative balance protection",
			h4: "Awards and Honors",
			p9: "We have always pursued excellence, striving for excellence, and are committed to providing our clients with quality transaction services.",
			p10: "The Richard brand is honored to receive an award from a prestigious organization in the industry, recognizing the team's constant efforts and commitment to its clients.",
			new_add: "New users worldwide",
			ol1: "Best News and Analysis Provider",
			ol2: "Best Forex Broker in Asia",
			ol3: "Australian Forex Customer Satisfaction Awards",
			ol4: "Best Mobile Application",
			ol5: "Australia's fastest growing broker",
			h5: "Richard's Operating Philosophy",
			tip_tit1: "Obligations",
			tip_tit2: "Simple but beautiful",
			tip_tit3: "Open (non-secretive)",
			tip_tit4: "Blaze new trails",
			tip_p1: "All trading involves risk, and Richard has taken additional steps to provide a responsible trading environment for its customers. Richard is strictly regulated by industry authorities, multiple organizations, and operates under high-level security and stability standards. Our platform continues to provide a number of responsible trading tools including risk management.",
			tip_p2: "Simplicity is beauty, details make excellence, Richard is dedicated to ease of use and user experience in every detail. We continue to optimize our platform and services in the hope that everyone, from newbies to experienced investors, will be able to take advantage of trading opportunities through the Richard platform.",
			tip_p3: "In order to allow our customers to fully benefit from a fair and efficient trading environment, Richard follows a rigorous and transparent self-regulatory system, provides comprehensive and transparent platform product information, pricing environment and data, and all fees are clearly and openly disclosed to ensure complete sincerity and transparency of the entire service process.",
			tip_p4: "Richard We firmly believe that technological progress, financial innovation, and broader services are the driving forces for growth, and only then can we continuously create and share value. For this reason, we encourage open thinking, continuously promote innovation, and aim to become innovators and leaders in financial technology.",
			row6_tit1: "Our Mission",
			row6_tit2: "Our Vision",
			row6_tit3: "Job Opportunities",
			row6_p1: "To provide a fair, efficient and transparent trading environment that is not limited by space, time and market for more people who want to trade, and to lead the industry in financial and technological innovation.",
			row6_p2: "We believe that technological advancement, financial innovation and diversified services are the forces that drive our growth and enable us to continue to create and share value in society.",
			row6_p3: "To this end, we encourage open-mindedness and promote innovation, aiming to become, through continuous growth and progress, the",
			row6_p4: "The world's leading foreign exchange company",
			row6_p5: "We are a global team across 7 countries and with a rapidly growing team, we are always looking for professionals to join us. Do what you love with a multicultural team!",
			row6_p6: "Please email us your up-to-date resume and why you would like to work with us:",
			row6_p7: "Alternatively, you can also find out more about the",
			row6_p8: "Maybe",
			row6_p9: "View our open vacancies on。",
			row6_p10: "Richard strictly protects the security of your personal information. Richard will not ask you for financial information during the job application process. We do not require any financial related, credit card, bank account information or payment of any kind for our recruitment.",
			row6_p11: "If you suspect that a position is fraudulent, please contact us through the",
			row6_p12: "Contact Richard's Human Resources Department. If you confirm that you have been deceived by a false job posting, please contact your local department of the relevant organization.",

		}
	},
	product: {
		row2: {
			tit: "Trading varieties",
			sell: "Sell",
			buy: "Buy",
			trade: "Dealings",
			more_product: "More Trading Products",
		},
		row3: {
			tit: "Supports multiple device transactions",
			tit1: "Supports multiple device transactions",
			p1: "Richard provides you with web-based, mobile and desktop trading platforms. You can trade anytime, anywhere.",
			download: "Downloading",
		},
		row4: {
			tit: "Select Richard",
			tit1: "Simple and intuitive platform",
			p1: "Integration of quotes, trading, account management, information, risk management, powerful functions, to achieve a better user experience",
			tit2: "Competitive transaction costs",
			p2: "0 trading commissions, very competitive and transparent low spreads, low SWAPs, so you can enjoy low-cost trading!",
			tit3: "Low-threshold trading conditions",
			p3: "The minimum lot size for each transaction is as low as 0.01 lot, and you can open a position with a low margin.",
			tit4: "Regulated by authority",
			p4: "Authorized and regulated by industry authorities, all clients' deposits are stored separately and segregated to protect the safety of clients' assets",
			tit5: "Negative balance protection",
			p5: "Your account will never lose more than its principal under any market condition, and negative balances will be cleared in a timely manner to enhance your risk management ability.",
			tit6: "High level of online support",
			p6: "Provide fast response online customer service, will provide better service through the unremitting efforts of the professional team",
		},
		row5: {
			tit: "Related issues",
		},
		commodities: {
			title: "Commodity trade",
			p: "Commodities such as precious metals and energy are considered to be an important part of diversified investment as they are considered to be both inflation-proof and have the opportunity to add value to the investment. Among them, gold, silver and oil are commodities traded in huge volumes, and their open and transparent prices as well as high liquidity have been widely welcomed by investors. Commodity prices fluctuate significantly due to factors such as supply and demand, economic and policy situations, and currencies, providing opportunities for risk-return.",
		},
		forex: {
			title: "Foreign exchange trading",
			p: "Forex trading is the conversion of one currency into another, and the foreign exchange market is the most heavily traded financial market in the world. The foreign exchange market has a large number of participants who trade through it to make payments, hedge against the risk of currency movements, or to make a profit. Forex trading takes place through a network of banks, institutions and individual traders around the globe, with price fluctuations 24 hours a day, 5 days a week, providing opportunities for risk-reward.",
		},
		indices: {
			title: "Indices",
			p: "A stock index is a statistical value that represents a particular type of characteristic stock on a particular exchange, and is an indicator of the overall price level and movement of a particular type of constituent stock in the market. Trading stock indices that reflect investment opportunities in the overall market or sector can reduce the risk of investing in individual stocks. The most popular indices in the U.S., European, Asian and Australian markets, such as the Australia 200, the U.S. Tech 100, the Hong Kong 50, the Germany 30, etc., offer different opportunities in the global stock markets.",
		},
		shares: {
			title: "Share Trading",
			p: "A stock is a marketable security issued on an exchange. In order to raise capital, companies distribute ownership of the company through the listed exchanges using the certificates. In 2019 alone, world stock trading totaled more than $60 trillion, which is so large and liquid that it remains one of the most popular financial instruments in the eyes of investors around the world, even after many years. On the Richard platform, traders can utilize leverage to trade price changes in stocks. Stock prices are affected by a variety of factors such as macroeconomics, industry outlook and company operations, making them highly volatile and there are always opportunities for investment trading.",
		},
		coins: {
			title: "Cryptocurrencies",
			p: "Cryptocurrencies are a digital currency created through code. They operate autonomously outside of traditional banking and government systems. As the name suggests, cryptocurrencies use encryption to secure transactions and provide for the creation of other units, with Bitcoin being the original and by far the most famous cryptocurrency, Bitcoin was created by Satoshi Nakamoto and launched in January 2009. Even today, it remains unknown whether the name Satoshi Nakamoto refers to a person or a group of people. Bitcoin is considered to be the first decentralized cryptocurrency. Like all cryptocurrencies, it is controlled through a blockchain transaction database that serves as a distributed public ledger. To date, more than 1,000 cryptocurrencies are traded online."
		}
	},
	trade: {
		data_sources: "Data sources",
		tip: " The above information is for reference only. Richard does not guarantee the accuracy, timeliness or completeness of the information and you should not place undue reliance on the information provided. This information does not include our price records or any offer or solicitation to trade any financial instruments. Richard is not a financial advice company and only provides services of an execution nature. We recommend that readers seek their own investment advice. Please see our full disclaimer.",
		tip2: "Updated every Friday at 15:00 (GMT)",
		tip3: "Trading Perspectives is a tool for predicting market trading sentiment, reflecting industry experts' short- and medium-term views and trend forecasts for each hot product.",
		analysis: {
			tit: "Trading Strategies",
			all: "Full",
			day: "In a few days",
			tit1: "Trading Strategies",
			tit2: "Technical Review",
		},
		calendar: {
			tit: "Financial calendar",
			prev_week: "Last week",
			next_week: "Next week",
			place_date: "Search Date",
			select: "Options",
			select1: "Our country",
			select2: "Significance",
			start_time: "Starting time",
			end_time: "Finish time",
			search: "Look for sth.",
		},
		contract: {
			tit: "Contract Specifications",
			type1: "Full",
			type2: "Forex",
			type3: "Commodities",
			type4: "Indices",
			type5: "US stock",
			type6: "Australian Shares",
			tit1: "Major foreign exchange",
			tit2: "Minor foreign exchange",
			tit3: "Commodities",
			tit4: "Main indices",
			tit5: "Minor index",
			tit6: "US stock",
			tit7: "Australian Shares",
			tit8: "General rules",
			p1: "Forced flatness ratio",
			p2: "Pending order validity",
			p3: "Limitless",
			p4: "Overnight fee settlement time",
			p5: "Daily settlement; Summer: 05:00, Winter: 06:00",
			p6: "Information on leverage, overnight rates, etc.",
			p7: "See Trading Platforms",
			p8: "The above applies to all trading varieties",
			tit9: "Trading Weekly Calendar",
			p9: "Closed",
			p10: "The above trading hours do not take into account the impact of holidays or special market adjustments.。",
			p11: "Specific trading time recommendations",
			p12: "Login Platform",
			p13: "，Check out the profiles of the relevant varieties。",
		},
		forecast: {
			tit: "Trading Perspectives",
			p1: "Average value",
			p2: "Bullish",
			p3: "Bearish",
			p4: "Sideways",
			p5: "Orientations",
		},
		news: {
			tit: "Real-time news",
		},
		platforms: {
			tit: "Richard trading platform",
			p: "Simple and intuitive interface, excellent trading performance, meets the needs of more investors. Start trading with Richard today.",
			tit1: "Convenient and easy-to-use Richard trading platform.",
			tit2: "Trade anytime, anywhere with the Richard App!",
			li1: "Trade hundreds of popular markets with authoritative regulation to ensure the safety of funds",
			li2: "Monitor market trends in real time, view trading analysis, and browse the latest financial news.",
			li3: "View different types of trading charts at any time and apply charting functions without any hassle",
			li4: "Experience Richard's award-winning trading platform on your Android or iOS device!",
			code_download: "Scan the code to download",
			tit3: "No need to download, trade online through your browser",
			li5: "No download required, trade online directly from your PC browser",
			li6: "Award Winning: Best Forex Broker in Asia 2022, Best Trading Platform in Australia 2022, Fastest Growing Forex Fintech Broker in the World 2022",
			li7: "Provides powerful technical indicators: MACD, KDJ, RSI, TRIX, DMA, CCI, etc.",
			li8: "Equipped with risk management tools such as stop-loss and negative balance protection to mitigate the potential risk of your trades",
			tit4: "The perfect solution for your desktop transactions",
			li9: "Easily view the market quotes in the self-selected list",
			li10: "Turn on alerts to receive price change alerts and other information notifications",
			li11: "Multiple chart layouts allow you to track the market from different time dimensions.",
			li12: "Contract specifications, trading sentiment and trading analytics all in one interface, no switching required",
			li13: "Get the latest trading news directly in Richard!",
			tit5: "Why choose Richard trading platform?",
			row5_p1: "Safety",
			row5_p2: "0% commission",
			row5_p3: "Multi-platform support",
			row5_p4: "Technical Charts",
			row5_p5: "Access anytime, anywhere",
			row5_p6: "Various drawing tools",
		},
		quotes: {
			tit: "Market Data",
			p1: "Provides you with instant quotes, market sentiment and related news on popular financial instruments of the moment.",
			hot: "Popular",
		},
		risk: {
			tit: "Risk Management System",
			p1: "All trading involves risk. By utilizing the free risk management tools provided by Richard, you can effectively control your risk regardless of market conditions.",
			function_tit1: "Take Profit/Stop Loss Function",
			function_tit2: "Trailing Stop Function",
			function_p1: "Locking in profits",
			function_p2: "Limit losses",
			function_p3: "Maximize profit lock-in",
			function_p4: "No need to monitor positions automatically",
			function_p5: "Adjustment of loss closing price",
			row2_tit1: "Take Profit/Stop Loss Function",
			row2_p1: " When creating a new order or modifying an existing order, we offer the option to set 'Take Profit' and 'Stop Loss'. Once set, the order will normally be closed at the target price you have set, helping you to lock in your profits when you reach your target, or to minimize your losses if the market moves unfavorably. Please note that any order may be shorted due to market volatility, in which case the system will not execute the order at the default price, but will close your position at the next most favorable price from the target price.",
			row2_tit2: "Example ",
			row2_p2: "The current price of EUR/USD is 1.13816/1.13837 (Sell/Buy). You open a buy order for 1 lot (1 lot = 100,000 EUR) at 1.13837 and set a Stop Loss at 1.13806.",
			row2_p3: "In general, when the price falls to 1.13806, your stop loss order will be triggered and the position will be closed at 1.13806 for a combined loss of $31.",
			row2_p4: "However, when there is a sudden change in the market environment and the price slides directly from 1.13837 to 1.13795, directly skipping your Stop Loss target, the market jumps and the system will not be able to close the position at 1.13806, but it will be executed at the next most favorable price, i.e., at 1.13795, and the final loss will be 42 USD.",
			row3_tit1: "Trailing Stop Function",
			row3_tit2: "Example",
			row3_p1: "Trailing stops (also known as trailing stops) are powerful, as they automatically change in line with your profits, allowing you to maximize your profits or minimize your losses without having to constantly monitor your positions. When you create a new order, you simply set a trailing stop, and when the price moves in your favor, your stop order is automatically updated with the latest price. Conversely, when the price moves against you, the stop loss order will be activated and the position will be closed at the distance you have set for the loss. Please note that any order may be shorted due to market volatility, in which case the system will not be able to execute the order at your default price, but will close the position for you at the next most favorable price from the target price.",
			row3_p2: "The current price of EUR/USD is 1.13816/1.13837 (sell/buy). You have established a 1-lot buy order at 1.13837 with a trailing stop loss of 100 pips (0.00100).",
			row3_p3: "When the price of the product is at 1.13816, your Stop Loss is 1.13716. If the selling price of the product moves up to 1.13845, the Stop Loss will be updated by the distance you have set, and the updated Stop Loss will be 1.13745.",
			row3_p4: " On the contrary, when the product price slides from 1.13845 to 1.13745, the trailing stop will be triggered and the position will be closed at 1.13745.",
			tip: "Point: Usually, the smallest unit of movement in the price of a financial instrument is called a point. On the Richard platform, it refers to the last digit or decimal of an instrument's price.",
		},
		sentiment: {
			tit: "Emotional index",
			type1: "Full",
			type2: "Forex",
			type3: "Commodities",
			type4: "Indices",
			long: "Long term (finance)",
			short: "Short",
		},
	},
	layout: {
		aside: {
			nav1: "Dealings",
			nav2: "Market",
			nav3: "News",
			nav4: "College",
			nav5: "My",
			newsDialog: {
				title: "News Center",
				type1: "System notification",
				type2: "Bulletin",
				allRead: "Mark all as read",
			},
			settingDialog: {
				title: "Set up",
				nav1: "General",
				nav2: "Show Preferences",
				nav3: "System Info",
				logout: "Log out",
				setting1: "Multilingualism",
				setting2: "Dealings",
				setting2_tip: "Automatically add self-selected varieties to open positions",
				setting3: "send back information",
				setting3_tip: "Functional Recommendations",
				setting4: "Real name verification",
				setting4_tip: "Real name verification",
				setting5: "Theme Mode",
				setting5_label1: "Obscurely",
				setting5_label2: "Bright color",
				setting6: "Rising or falling color",
				setting6_label1: "Lit. green rises and red falls",
				setting6_label2: "Lit. red rises, green falls",
				setting7: "Chart Setup",
				setting7_label1: "Standard Edition",
				setting7_label2: "TradingView Edition",
				setting8: "Declarations and agreements",
				setting8_label1: "Privacy policy",
				setting8_label2: "Product Disclosure Statement",
				setting8_label3: "Risk Disclosure Statement",
				setting8_label4: "Customer agreement",
				feedback_title: "Functional Recommendations",
			}
		},
		footer: {
			hot: "Popular varieties",
			sort: "Symbol",
			code: "Coding",
			buy_price: "Buying price",
			sell_price: "Seller's price",
			chg: "Rise or fall in price",
		},
		header: {
			place_search: "Search for trading varieties",
			balance: "Available",
			balance_tip: "Available: the amount of money in the account that can be used to open new positions",
			profit_loss: "P/L",
			asset_view: "Overview of assets",
			netValue: "Net value",
			netValue_tip: "Net worth: the current account value, including the profit and loss of all positions",
			marginLevel: "Margin level",
			margin: "Margin (in derivative trading)",
			margin_tip: "Margin: the amount required to open and maintain a position",
			maintainsMargin: "Maintenance margin",
			maintainsMargin_tip: "Maintenance Margin: The minimum amount of margin that needs to be maintained in your account while you hold all your positions.",

		},
	},
	table: {
		label_name: "Name",
		label_buyPrice: "Buy",
		label_sellPrice: "Sell",
		label_variableValue: "Change",
		trade: "Dealings",
		label_code: "Symbol",
		label_title: "Name",
		label_start: "Significance",
		label_country: "Our country",
		label_event: "Event",
		label_previous: "Previous value",
		label_predictive: "Projected value",
		label_announced: "Published value",
		label_stop_loss_min: "Minimum distance for stop loss orders",
		label_difference: "Dynamic Spreads",
		label_contract_size: "Contract size",
		label_max_hand: "Maximum lot size for a single transaction",
		label_min_hand: "Minimum lot size",
		label_trade_week: "Trading Weekly Calendar",
		label_week: "1 week",
		label_month: "1 January",
		label_quarter: "1 quarter",
		label_dailyTrend: "Single Day Trend",
		label_tradeId: "Transaction ID",
		label_breed: "Symbol",
		label_tradeType: "Type of transaction",
		label_currency: "Money",
		label_amount: "Money",
		label_balance: "Balances",
		label_type: "Typology",
		label_time: "Timing",
		label_orderNumber: "OrderNum",
		label_pointer_number: "Handful (of)",
		label_price_money: "Opening",
		label_stop_win_price: "Take profits",
		label_stop_lose_price: "Stop-loss",
		label_openTime: "OpenTime",
		label_profit_loss: "P/L",
		label_sell_buy: "Ask/Buy price",
		label_chg: "Rise or fall in price",
		label_sell_price: "Seller's price",
		label_buy_price: "Buying price",
		label_condition: "Prerequisite",
	},
	form: {
		label_feedback: "Issues and recommendations",
		place_feedback: "Please enter your question or suggestion",
		label_img: "Photograph",
		label_img_tip: "Optional, provide a screenshot of the question",
		feedback_tip: "If you have an urgent question, please contact",
		online: "Online Customer Service",
		label_name: "Names",
		place_name: "Please enter your name",
		label_email: "E-mail address",
		place_email: "E-mail address",
		message_email: "Please enter your e-mail address",
		label_nationality: "Citizenship",
		place_nationality: "Please enter your nationality",
		place_questionType: "Type of problem",
		message_questionType: "Please select type",
		questionType0: "Trading platform",
		questionType1: "Products & Fees",
		questionType2: "Subscribe to daily market analysis",
		questionType3: "Client service",
		questionType4: "The rest",
		place_question: "Your question",
		message_question: "Please enter a question",
		submit_question: "Submission of questions",
		label_phone: "Cell phone number",
		place_phone: "Please enter your cell phone number",
		message_phone: "Please enter your cell phone number",
		label_password: "Cryptographic",
		place_password: "Please enter your password",
		message_password: "Please enter your password",
		label_confirmPassword: "Confirm Password",
		place_confirmPassword: "Please enter your password again",
		message_confirmPassword: "Please enter your password again",
		label_captcha: "CAPTCHA, a type of challenge-response test (computing)",
		place_captcha: "Please enter the verification code",
		message_captcha: "Please enter the verification code",
		get_captcha: "Verification Code",
		label_inviteId: "Invitation code",
		place_inviteId: "Invitation Code (optional)",
		to: "To",
		start_time: "Starting time",
		end_time: "End time",
		label_cardNumber: "ID Number",
		place_cardNumber: "Please enter your ID number",
		message_cardNumber: "Please enter your ID number",
		label_cardMain: "Front of the document",
		message_cardMain: "Please enter the front of the document",
		label_cardBack: "Reverse side of a document",
		message_cardBack: "Please enter the reverse side of the document",
		confirm_modify: "Confirmation of changes",
		label_realName: "First Name",
		place_realName: "Please enter your First Name",
		message_realName: "Please enter your First Name",
		label_firstName: "Last name",
		place_firstName: "Please enter your last name",
		message_firstName: "Please enter your last name",
		label_lastName: "Middle Name",
		place_lastName: "Optional",
		message_lastName: "Please enter Middle Name",
		label_birthday: "Birth",
		place_birthday: "Please enter your date of birth",
		message_birthday: "Please enter your date of birth",
		place_nowPsd: "Please enter your current password",
		message_nowPsd: "Please enter your current password",
		place_newPsd: "Please enter a new password",
		message_newPsd: "Please enter a new password",
		place_crmPsd: "Please confirm the new password",
		message_crmPsd: "Please confirm the new password",
		label_breed: "Symbol",
		place_breed: "Please enter a species",
		label_phase: "Be",
		label_buyPrice: "Buying price",
		label_sellPrice: "Seller's price",
		label_do: "Because of",
		label_height: "Greater than",
		label_low: "Be lower than",
		label_equal: "Or equal to",
		labelWalletName: "Wallet Name",
		messageWalletName: "Please enter your wallet name",
		placeWalletName: "Please enter your wallet name",
		labelWalletAddress: "Wallet Address",
		messageWalletAddress: "Please enter your wallet address",
		placeWalletAddress: "Please enter your wallet address",
		labelAmount: "Money",
		messageAmount: "Please enter the amount",
		placeAmount: "Please enter the amount",
		placeTimeLimit: "Please enter a time limit",
		messageTimeLimit: "Please enter a time limit",
	},
	pay: {
		title: "Easy access to funds",
		p1: "A variety of commonly used payment channels, support for rapid deposit and withdrawal",
		p2: "Some payment methods may not be available in your country.",
		p3: "Welcome to experience Richard service!",
		btn: "Trade Now ",
	},
	header: {
		tip1: "Leveraged trading is a complex financial product with a high risk of rapid loss.",
		btn1: "trading platform",
		btn2: "Log Out",
		btn3: "Log In",
		BigUint64Array: "Trade Now",
	},
	footer: {
		help: "Contact Us",
		tip1: "Follow our community",
		tip2: "Please note that Richard has not set up an official Telegram group, and any Telegram group formed in the name of Richard is suspected of spoofing.",
		tip3: "RISK WARNING: Trading may result in the loss of all your funds. OTC derivatives trading is not suitable for everyone. Please read our legal documents carefully before using our services and ensure that you fully understand the risks involved before trading. You do not actually own or hold any of the underlying assets.",
		tip4: "Richard does not provide any advice, recommendation or opinion on buying, holding or selling. All products we offer are OTC derivatives based on global assets.All services provided by Richard are based on the execution of trade orders only.",
		tip5: "Richard is a business brand shared by several companies and operated through the following companies:",
		tip6: "Richard International Ltd is the issuer of the financial products described or available for use on this website Richard International Ltd is authorized and regulated by the Financial Services Commission (FSC) of Mauritius under license number GB20025791, with its registered address at 6 St Denis Street, 1st Floor River Court, Port Louis 11328, Mauritius",
		tip7: "Richard Global Pty Ltd is registered under ABN 90 149 011 361, Australian Financial Services License (AFSL) number 398528.",
		tip8: "Richard Holding Ltd is authorized and regulated by the Cayman Islands Monetary Authority (CIMA) under SIB license number 1612446.",
		tip9: "The information on this website is not intended for residents of the United States of America, Canada, Japan or New Zealand, nor is it intended for use by anyone in any country or jurisdiction where its publication or use would be contrary to local law or regulation. Please note that English is the primary language of our services and is the legally binding language of all our contractual documents. Translations into other languages are for reference only, and in the event of any discrepancy between the English and the Chinese versions, the English version shall prevail.",
		tip10: "SSL secure communication encryption. © Richard All rights reserved.",
		link1: "Privacy policy",
		link2: "Product Disclosure Statement",
		link3: "Complaints process",
		link4: "Risk Disclosure Statement",
		link5: "Customer agreement",
		toTop: "Sticky (of an Internet forum thread etc)",
	},
	nav: {
		tit1: "Markets",
		tit2: "Trading",
		tit3: "Learn",
		tit4: "About Us",
		nav1: "Forex",
		nav2: "Indices",
		nav3: "Commodities",
		nav4: "Shares",
		nav5: "Trading Platforms",
		nav6: "Trading Strategies",
		nav7: "Trading Perspectives",
		nav8: "Financial calendar",
		nav9: "Real-time news",
		nav10: "Market Data",
		nav11: "Emotional index",
		nav12: "Risk Management System",
		nav13: "Contract Specifications",
		nav14: "Trading Basics",
		nav15: "Investment insight",
		nav16: "Academy",
		nav17: "About Richard",
		nav18: "Our Awards",
		nav19: "Media Center",
		nav20: "Client Funds Security",
		nav21: "Fees & Charges",
		nav22: "Affiliates",
		nav23: "Contact Us",
		nav24: "Help Center",
		nav25: "Help Center",
	},

	city: {
		albania: "Albania",
		algeria: "Algeria",
		argentina: "Argentina",
		armenia: "ARMENIA",
		australia: "Australia",
		pakistan: "Pakistan",
		austria: "Austria",
		bahrain: "Bahrain",
		belgium: "Belgium",
		bosnia_and_Herzegovina: "Bosnia and Herzegovina",
		brazil: "Brazil",
		brunei: "Brunei",
		bulgaria: "Bulgaria",
		cambodia: "Cambodia",
		canada: "Canada",
		cameroon: "Cameroon",
		chile: "Chile",
		colombia: "Columbia",
		costa_Rica: "Costa Rica",
		croatia: "Croatia",
		cyprus: "Cyprus",
		czech_Republic: "Czech Republic",
		denmark: "Denmark",
		dominican_Republic: "DOMINICAN REPUBLIC",
		egypt: "Egypt",
		estonia: "Estonia",
		ethiopia: "Ethiopia",
		finland: "Finland",
		france: "France",
		georgia: "Georgia",
		germany: "Germany",
		ghana: "Ghana",
		greece: "Greece",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, China",
		hungary: "Hungary",
		iceland: "Iceland",
		ireland: "Ireland",
		italy: "Italy",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israel",
		iran: "Iran",
		iraq: "Iraq",
		japan: "Japan",
		kazakstan: "Kazakhstan",
		kenya: "Kenya",
		korea: "South Korea",
		kuwait: "Kuwait",
		kyrgyzstan: "Kyrgyzstan",
		laos: "Laos",
		latvia: "Latvia",
		lithuania: "Lithuania",
		luxembourg: "Luxembourg",
		macao_China: "Macao, China",
		macedonia: "Macedonia",
		malaysia: "Malaysia",
		malta: "Malta",
		mexico: "Mexico",
		moldova: "Moldova",
		monaco: "Monaco",
		mongolia: "Mongolia",
		montenegro: "MONTENEGRO",
		morocco: "Morocco",
		myanmar: "Myanmar",
		netherlands: " Netherlands",
		new_Zealand: "New Zealand",
		nepal: "Nepal",
		nigeria: "Nigeria",
		norway: "Norway",
		oman: "Oman",
		palestine: "Palestine",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Peru",
		philippines: "the Philippines",
		poland: "Poland",
		portugal: "Portugal",
		puerto_Rico: "Puerto Rico",
		qatar: "Qatar",
		romania: "Romania",
		russia: "Russia",
		republic_of_Trinidad_and_Tobago: "trinidad and tobago",
		rwanda: "Rwanda",
		saudi_Arabia: "Saudi Arabia",
		serbia: "Serbia",
		singapore: "Singapore",
		slovakia: "Slovakia",
		slovenia: "Slovenia",
		south_Africa: "South Africa",
		spain: "Spain",
		sri_Lanka: "Sri Lanka",
		sweden: "Sweden",
		switzerland: "Switzerland",
		taiwan_China: "Taiwan, China",
		tajikistan: "Tajikistan",
		tanzania: "Tanzania",
		thailand: "Thailand",
		turkey: "Turkey",
		turkmenistan: "Turkmenistan",
		ukraine: "Ukraine",
		united_Arab_Emirates: "The United Arab Emirates",
		united_Kingdom: "Britain",
		united_States: "America",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaijan",
		bangladesh: "Bangladesh",
		belarus: "Belarus",
		belize: "belize",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "british virgin islands",
		burkina_Faso: "burkina faso",
		burundi: "Burundi",
		cape_Verde: "Cape Verde",
		cayman_Islands: "Cayman Islands",
		central_African_Republic: "Central African Republic",
		chad: "Chad",
		comoros: "Comoros",
		the_Republic_of_the_Congo: "Congo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Congo (Kinshasa)",
		djibouti: "Djibouti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Equatorial Guinea",
		eritrea: "ERITREA",
		fiji: "Fiji",
		gabon: "Gabon",
		gambia: "Gambia",
		greenland: "Greenland",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Isle of Man",
		cote_d_Ivoire: "Côte d'Ivoire",
		jamaica: "Jamaica",
		jordan: "Jordan",
		lebanon: "Lebanon",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libya",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldives",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mozambique: "Mozambique",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "the Niger",
		north_Korea: "Korea",
		reunion: "Reunion",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "Sierra Leone",
		somalia: "Somalia",
		sudan: "sultan",
		suriname: "Surinam",
		eswatini: "Eswatini",
		syria: "Syria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisia",
		united_States_Virgin_Islands: "Virgin Islands, United States",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatican",
		yemen: "Yemen",
		yugoslavia: "Yugoslavia",
		zambia: "Zambia",
		zimbabwe: "Zimbabwe",
		china: "China",
	}
};
