<template>
  <div class="web-header">
    <div class="left">
      <img @click="$router.push('/')" class="logo" src="../../../static/image/logo3.png" alt="">
      <!--        <div class="dropdown-wrap">-->
      <!--          <div class="dropdown-title">-->
      <!--            <el-input class="search-input" :placeholder="$t('layout.header.place_search')" @focus="listShow = true"-->
      <!--                      @blur="listShow = false" v-model="search" size="small">-->
      <!--              <i slot="prefix" class="el-input__icon el-icon-search"></i>-->
      <!--            </el-input>-->
      <!--          </div>-->
      <!--          <div class="dropdown-body" v-show="listShow">-->
      <!--            <ul class="search-filter">-->
      <!--              <li :class="tabActive === 0 ? 'active' : ''" @click="tabActive = 0"><span>全部</span></li>-->
      <!--              <li :class="tabActive === 1 ? 'active' : ''" @click="tabActive = 1"><span>外汇</span></li>-->
      <!-- <li :class="tabActive === 2 ? 'active' : ''" @click="tabActive = 2"><span>商品</span></li> -->
      <!--              <li :class="tabActive === 3 ? 'active' : ''" @click="tabActive = 3"><span>股票</span></li>-->
      <!--              <li :class="tabActive === 4 ? 'active' : ''" @click="tabActive = 4"><span>指数</span></li>-->
      <!--              <li :class="tabActive === 5 ? 'active' : ''" @click="tabActive = 5"><span>加密</span></li>-->
      <!--            </ul>-->

      <!--            <div class="list-body">-->
      <!--              <div class="list-cell" v-for="i in 60">-->
      <!--                <div class="auto-item">-->
      <!--                  <div class="icon">-->
      <!--                    <i class="el-icon-star-off"></i>-->
      <!--                    &lt;!&ndash; <i class="el-icon-star-on"></i> &ndash;&gt;-->
      <!--                  </div>-->
      <!--                  <div class="name">澳元/加元</div>-->
      <!--                </div>-->
      <!--                <div class="auto-item">-->
      <!--                  <div class="symbol">AUDCAD</div>-->
      <!--                  <div class="type">外汇</div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
    </div>
    <div class="right">
      <div class="capital_info">
        <div class="item">
          <div class="value" v-if="!assetsHidden">{{ accountInfo.productTitle }}
            <span v-if="typeActive === 0">{{ accountInfo.bitRealMoney }}</span>
            <span v-if="typeActive === 1">{{ accountInfo.contractRealMoney }}</span>
            <span v-if="typeActive === 2">{{ accountInfo.optionsRealMoney }}</span>
          </div>
          <div class="value" v-else>{{ accountInfo.productTitle }} ******</div>
          <div class="label">{{ $t('layout.header.balance') }}</div>
        </div>
        <div class="item">
          <el-dropdown @command="typeChange">
            <span class="el-dropdown-link">
              <span v-if="typeActive === 0">{{ $t('addnew.accountType1') }}</span>
              <span v-if="typeActive === 1">{{ $t('addnew.accountType2') }}</span>
              <span v-if="typeActive === 2">{{ $t('addnew.accountType3') }}</span>
              <span></span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, i) in typeList" :command="item" :key="i">{{ item.name }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="item">
          <!-- <div class="value" v-if="!assetsHidden">USD {{ accountInfo.totalProfit - accountInfo.totalLoss }}</div>
          <div class="value" v-else>USD ******</div> -->
          <div class="value">
            <el-dropdown @command="currencyChange">
              <span class="el-dropdown-link">
                {{ nowCurrency.title }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, i) in currencyList" :key="i" :command="item">{{ item.title
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div class="label">{{ $t('layout.header.profit_loss') }}</div>
          <div class="assets-body" v-show="assetsShow">
            <div class="tit">{{ $t('layout.header.asset_view') }} (USD)</div>
            <div class="list">
              <div class="item">
                <div class="label">{{ $t('layout.header.netValue') }}
                  <el-tooltip class="item" effect="dark" :content="$t('layout.header.netValue_tip')"
                    placement="bottom-start">
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </div>
                <div class="value">{{ accountInfo.realMoney + accountInfo.promiseMoney }}</div>
              </div>
              <div class="item">
                <div class="label">{{ $t('layout.header.profit_loss') }}</div>
                <div class="value">{{ accountInfo.totalProfit - accountInfo.totalLoss }}</div>
              </div>
              <div class="item">
                <div class="label">{{ $t('layout.header.balance') }}
                  <el-tooltip class="item" effect="dark" :content="$t('layout.header.balance_tip')"
                    placement="bottom-start">
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </div>
                <div class="value">{{ accountInfo.realMoney }}</div>
              </div>
              <div class="item">
                <div class="label">{{ $t('layout.header.marginLevel') }}
                  <el-tooltip class="item" effect="dark" content="" placement="bottom-start">
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </div>
                <div class="value">{{ ((accountInfo.realMoney + accountInfo.promiseMoney) / accountInfo.promiseMoney *
                  100).toFixed(4) }}%</div>
              </div>
              <div class="item">
                <div class="label">{{ $t('layout.header.margin') }}
                  <el-tooltip class="item" effect="dark" :content="$t('layout.header.margin_tip')"
                    placement="bottom-start">
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </div>
                <div class="value">{{ accountInfo.promiseMoney }}</div>
              </div>
              <div class="item">
                <div class="label">{{ $t('layout.header.maintainsMargin') }}
                  <el-tooltip class="item" effect="dark" :content="$t('layout.header.maintainsMargin_tip')"
                    placement="bottom-start">
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </div>
                <div class="value">{{ (accountInfo.promiseMoney / 2).toFixed(4) }}</div>
              </div>
            </div>
          </div>
        </div>


        <!--        <div class="icon" style="margin-right: 0;">-->
        <!--          <i class="el-icon-info" @click="assetsShow = !assetsShow"></i>-->
        <!--        </div>-->
        <div class="icon">
          <i class="el-icon-view" @click="assetsHidden = !assetsHidden"></i>
        </div>
        <el-button class="deposit-btn" type="primary" @click="$router.push('/web')">{{ $t('common.deposit') }}</el-button>
        <!--        <el-dropdown>-->
        <!--          <span class="el-dropdown-link">-->
        <!--            {{ $t('common.real_trade') }}-->
        <!--            &lt;!&ndash;              <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>&ndash;&gt;-->
        <!--          </span>-->
        <!--          <el-dropdown-menu slot="dropdown">-->
        <!--            &lt;!&ndash;              <el-dropdown-item>{{ $t('common.real_trade') }}</el-dropdown-item>&ndash;&gt;-->
        <!--            &lt;!&ndash;              <el-dropdown-item>{{ $t('common.paper_trade') }}</el-dropdown-item>&ndash;&gt;-->
        <!--          </el-dropdown-menu>-->
        <!--        </el-dropdown>-->
        <!--        <div class="theme-box">-->
        <!--          &lt;!&ndash;            <div class="icon-box active">&ndash;&gt;-->
        <!--          &lt;!&ndash;              <i class="el-icon-sunny"></i>&ndash;&gt;-->
        <!--          &lt;!&ndash;            </div>&ndash;&gt;-->
        <!--          &lt;!&ndash;            <div class="icon-box">&ndash;&gt;-->
        <!--          &lt;!&ndash;              <i class="el-icon-moon"></i>&ndash;&gt;-->
        <!--          &lt;!&ndash;            </div>&ndash;&gt;-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { getProduct } from "@/api/product";
import { baseUsdtId } from "@/api/global";
import { getAccountProduct } from "@/api/account";

export default {
  props: {},
  data() {
    return {
      search: undefined,
      tabActive: 0,
      listShow: false,
      assetsShow: false,
      assetsHidden: false,
      accountInfo: {},
      typeActive: 0,

      currencyList: [],
      nowCurrency: {},
    };
  },
  created() {
    this.getData()
  },
  computed: {
    typeList() {
      return [
        { v: 0, name: this.$t('addnew.accountType1') },
        { v: 1, name: this.$t('addnew.accountType2') },
        { v: 2, name: this.$t('addnew.accountType3') },
      ]
    }
  },
  mounted: function () {

  },
  methods: {
    getData() {
      getProduct({ typeId: 6 }).then(response => {
        this.currencyList = response.Data.content
        let usdt = { id: baseUsdtId, title: "USDT" }
        this.currencyList.push(usdt)
        this.nowCurrency = usdt
        getAccountProduct(baseUsdtId).then(response => {
          this.accountInfo = response.Data
        })
      })
    },
    typeChange(item) {
      this.typeActive = item.v
    },
    currencyChange(item) {
      this.nowCurrency = item
      getAccountProduct(item.id).then(response => {
        this.accountInfo = response.Data
      })
    },
  },
}
</script>

<style lang="less" scoped>
.web-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  -webkit-app-region: drag;
  user-select: none;
  --el-header-padding: 0 12px;
  background: var(--background-w1);
  padding: 0 12px;

  .left {
    display: flex;
    gap: 20px;
    align-items: center;

    .logo {
      width: 130px;
      //height: 36px;
      margin-left: 12px;
      cursor: pointer;
    }

    .dropdown-wrap {
      .dropdown-title {
        /deep/ .search-input {
          .el-input__inner {
            background-color: var(--background-w16);
            border-radius: 4px;
            border: none;
            box-shadow: none !important;
            width: 337px;
            height: 28px !important;
            line-height: 28px !important;
            padding-left: 32px !important;
            font-size: 11px;
            color: var(--font-icon-t2);
          }
        }
      }

      .dropdown-body {
        position: absolute;
        background-color: var(--background-w29);
        z-index: 2001;
        box-shadow: var(--color-h2);
        border-radius: 4px;
        border: 1px solid var(--background-w4);
        width: 350px;
        width: 335px;
        color: var(--el-text-color-primary);


        .search-filter {
          display: flex;
          flex-wrap: wrap;
          flex: 1;
          width: 100%;
          justify-content: flex-start;
          align-items: center;
          height: 34px;
          overflow: hidden;
          border-bottom: 1px solid var(--background-w6);

          li {
            padding: 0 4px;
            line-height: 32px;

            &:first-child {
              padding-left: 0;
            }

            &.active {
              color: var(--el-color-primary);
              border-bottom: 1px solid var(--el-color-primary);
              font-weight: 700;
            }

            span {
              padding: 2px 4px;
              font-size: 12px;
            }
          }
        }

        .list-body {
          height: 664px;
          overflow-y: scroll;


          &::-webkit-scrollbar {
            width: 4px;
          }

          // &::-webkit-scrollbar-button {
          //     display: none;
          // }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            opacity: 0.2;
          }

          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 0;
          }


          .list-cell {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 16px;

            .auto-item {
              padding: 5px 0;
              display: flex;
              align-items: center;
              font-size: 12px;

              .icon {
                width: 16px;

                .el-icon-star-on {
                  color: #FFAE00;
                }
              }

              .name {
                width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
              }

              .symbol {
                width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                text-align: center;
              }

              .type {
                width: 70px;
                color: var(--font-icon-t2);
                text-align: right;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .capital_info {
      display: flex;
      align-items: center;

      .item {
        text-align: right;
        position: relative;
        cursor: pointer;
        margin-right: 24px;

        &:nth-last-of-type(1) {
          margin-right: 0;
        }

        &:nth-of-type(1) {}

        .value {
          color: #000;
          font-size: 14px;
          font-weight: 600;
        }

        .label {
          font-size: 11px;
          color: var(--el-text-color-secondary);
          display: block;
          align-items: center;
          line-height: 12px;
        }

        .assets-body {
          position: absolute;
          top: 38px;
          left: -59.7969px;
          background-color: var(--background-w29);
          z-index: 2001;
          box-shadow: var(--color-h2);
          border-radius: 4px;
          border: 1px solid var(--background-w4);
          width: 400px;
          display: flex;
          flex-direction: column;
          text-align: left;

          .tit {
            font-size: var(--el-font-size-base);
            font-weight: var(--cc-text-font-weight);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 20px;
            color: var(--el-text-color-primary);
            border-bottom: 1px solid var(--background-w6);
          }

          .list {
            display: flex;
            flex: 1;
            gap: 7px;
            flex-wrap: wrap;
            padding: 12px 20px;

            .item {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 4px;
              width: 160px;
              margin-right: 0;

              .label {
                font-size: var(--el-font-size-base);
                color: var(--el-text-color-secondary);
                display: flex;
                align-items: center;
                // gap: 3px;
                line-height: 14px;

                i {
                  margin: 0;
                  width: auto;
                  margin-left: 5px;
                }
              }

              .value {
                color: var(--el-text-color-primary);
                font-size: var(--el-font-size-medium);
                line-height: 22px;
              }
            }
          }

          .more {
            display: flex;
            padding: 8px 24px 12px;
            align-items: center;
            font-size: 12px;
            color: var(--el-text-color-secondary);
          }
        }
      }
    }

    .icon {
      font-size: 12px;
      color: var(--el-text-color-secondary);
      margin-left: 8px;
      margin-right: 24px;
      cursor: pointer;
    }

    .deposit-btn {
      margin-right: 24px;
      padding: 0 23px;
      height: 28px;
      color: #FFF;
      font-size: 14px;
    }

    .theme-box {
      display: flex;
      width: 60px;
      height: 28px;
      display: flex;
      border-radius: 16px;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      background: var(--background-w6);
      -webkit-app-region: no-drag;
      margin: 0 20px;

      .icon-box {
        width: 28px;
        height: 28px;
        line-height: 28px;
        text-align: center;

        &.active {
          background: var(--color-b1);
          border-radius: 50%;
          color: #FFF;
        }
      }
    }
  }
}
</style>
