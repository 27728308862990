<template>
  <div class="aside aside-menu">
    <div class="tac">
      <div class="top">
        <ul>
          <li :class="nowPath === '/web/trade' ? 'active' : ''"
              @click="$router.push('/web/trade');nowPath = '/web/trade'">
            <i class="iconfont icon-jiaoyishuju"></i>
            <p>{{ $t('layout.aside.nav1') }}</p>
          </li>
          <li :class="nowPath === '/web/markets' ? 'active' : ''"
              @click="$router.push('/web/markets');nowPath = '/web/markets'">
            <i class="iconfont icon-line-achievement"></i>
            <p>{{ $t('layout.aside.nav2') }}</p>
          </li>
<!--          <li :class="nowPath === '/web/news' ? 'active' : ''" @click="$router.push('/web/news');nowPath = '/web/news'">-->
<!--            <i class="iconfont icon-zixun"></i>-->
<!--            <p>{{ $t('layout.aside.nav3') }}</p>-->
<!--          </li>-->
          <!--                    <li>-->
          <!--                        <i class="iconfont icon-shipin"></i>-->
          <!--                        <p>{{ $t('layout.aside.nav4') }}</p>-->
          <!--                    </li>-->
          <li :class="nowPath === '/web/account' ? 'active' : ''"
              @click="$router.push('/web/account');nowPath = '/web/account'">
            <i class="iconfont icon-wode"></i>
            <p>{{ $t('layout.aside.nav5') }}</p>
          </li>
          <li :class="nowPath === '/web/loan' ? 'active' : ''"
              @click="$router.push('/web/loan');nowPath = '/web/loan'">
            <i class="iconfont icon-daikuan"></i>
            <p>{{$t('addnew.loan')}}</p>
          </li>
        </ul>
      </div>
      <div class="bottom">
        <ul>
          <!--                    <li>-->
          <!--                        <i class="iconfont icon-qiehuan"></i>-->
          <!--                    </li>-->
          <li @click="$router.push('/web')">
            <i class="iconfont icon-kefu"></i>
          </li>
          <!-- 消息中心 -->
          <!--          <li @click="newsDialog = true">-->
          <!--            <i class="iconfont icon-xiaoxi"></i>-->
          <!--          </li>-->
          <li @click="settingDialog = true">
            <i class="iconfont icon-shezhi"></i>
          </li>
        </ul>
      </div>
    </div>
       <!-- 消息中心 -->
    <!-- <el-dialog class="newsDialog" :title="$t('layout.aside.newsDialog.title')" :visible.sync="newsDialog" width="600px"
               :close-on-click-modal="false">
      <div class="newsDialog-cont">
        <div class="top">
          <div class="left">
            <el-badge :value="8" class="item">
              <span :class="newType === 0 ? 'active' : ''" @click="newType = 0">{{$t('layout.aside.newsDialog.type1')}}</span>
            </el-badge>
            <el-badge :value="48" class="item">
              <span :class="newType === 1 ? 'active' : ''" @click="newType = 1">{{$t('layout.aside.newsDialog.type2')}}</span>
            </el-badge>
          </div>
          <div class="right">{{$t('layout.aside.newsDialog.allRead')}}</div>
        </div>

        <el-scrollbar class="list">
          <div class="box">
            <ul>
              <li v-for="item in 16">
                <div class="type">
                  <el-badge is-dot class="item">
                    <i v-if="newType === 0" class="iconfont icon-zoushi"></i>
                    <i v-if="newType === 1" class="iconfont icon-xiaoxitongzhi"></i>
                  </el-badge>
                </div>
                <div class="new-main">
                  <div class="tit">2023/08 – 交易时间安排</div>
                  <div class="time">2023/8/9 08:00:00</div>
                </div>
              </li>
            </ul>
          </div>
        </el-scrollbar>

      </div>
    </el-dialog> -->

    <el-dialog class="settingDialog" :title="$t('layout.aside.settingDialog.title')" :visible.sync="settingDialog" width="800px"
               :close-on-click-modal="false">
      <div class="settingDialog-cont">
        <div class="tabs">
          <div class="tab" :class="settingTab === 0 ? 'active' : ''" @click="settingTab = 0">
            <i class="iconfont icon-shezhi"></i>{{$t('layout.aside.settingDialog.nav1')}}
          </div>
<!--          <div class="tab" :class="settingTab === 1 ? 'active' : ''" @click="settingTab = 1">-->
<!--            <i class="iconfont icon-yanse"></i>{{$t('layout.aside.settingDialog.nav2')}}-->
<!--          </div>-->
          <div class="tab" :class="settingTab === 2 ? 'active' : ''" @click="settingTab = 2">
            <i class="iconfont icon-wenben"></i>{{$t('layout.aside.settingDialog.nav3')}}
          </div>
          <div class="tab logout" @click="logout">
            <i class="iconfont icon-tuichu"></i>{{$t('layout.aside.settingDialog.logout')}}
          </div>
        </div>
        <div class="setting-cont" v-show="settingTab === 0">
          <div class="item">
            <div class="title">{{$t('layout.aside.settingDialog.setting1')}}</div>
            <el-select v-model="languageActive" :placeholder="$t('common.place_select')" @change="selectLanguage">
              <el-option v-for="(item,i) in languageTotal" :key="i" :label="item.name" :value="item.abbr" />
            </el-select>
          </div>
<!--          <div class="item">-->
<!--            <div class="title">{{$t('layout.aside.settingDialog.setting2')}}</div>-->
<!--            <el-checkbox v-model="autoAdd">{{$t('layout.aside.settingDialog.setting2_tip')}}</el-checkbox>-->
<!--          </div>-->
          <div class="item">
            <div class="title">{{$t('layout.aside.settingDialog.setting3')}}</div>
            <p @click="feedbackDialog = true">{{$t('layout.aside.settingDialog.setting3_tip')}} <i class=" el-icon-arrow-right"></i></p>
          </div>
          <div class="item">
            <div class="title">{{$t('layout.aside.settingDialog.setting4')}}</div>
            <p @click="settingDialog = false;$router.push('/web/account')">{{$t('layout.aside.settingDialog.setting4_tip')}} <i class=" el-icon-arrow-right"></i></p>
          </div>
        </div>
        <div class="setting-cont" v-show="settingTab === 1">
          <div class="item">
            <div class="title">{{$t('layout.aside.settingDialog.setting5')}}</div>
            <div class="radio-group">
              <el-radio v-model="theme" label="1">{{$t('layout.aside.settingDialog.setting5_label1')}}</el-radio>
              <el-radio v-model="theme" label="2">{{$t('layout.aside.settingDialog.setting5_label2')}}</el-radio>
            </div>
          </div>
          <div class="item">
            <div class="title">{{$t('layout.aside.settingDialog.setting6')}}</div>
            <div class="radio-group">
              <el-radio v-model="themeColor" label="1">{{$t('layout.aside.settingDialog.setting6_label1')}}</el-radio>
              <el-radio v-model="themeColor" label="2">{{$t('layout.aside.settingDialog.setting6_label2')}}</el-radio>
            </div>
          </div>
          <div class="item">
            <div class="title">{{$t('layout.aside.settingDialog.setting7')}}</div>
            <div class="radio-group">
              <el-radio v-model="chart" label="1">{{$t('layout.aside.settingDialog.setting7_label1')}}</el-radio>
              <el-radio v-model="chart" label="2">{{$t('layout.aside.settingDialog.setting7_label2')}}</el-radio>
            </div>
          </div>
        </div>
        <div class="setting-cont" v-show="settingTab === 2">
          <div class="item">
            <div class="title">{{$t('layout.aside.settingDialog.setting8')}}</div>
            <div class="list">
              <p @click="goUrl(1)">{{$t('layout.aside.settingDialog.setting8_label1')}} <i class=" el-icon-arrow-right"></i></p>
<!--              <p @click="goUrl(2)">{{$t('layout.aside.settingDialog.setting8_label2')}} <i class=" el-icon-arrow-right"></i></p>-->
              <p @click="goUrl(3)">{{$t('layout.aside.settingDialog.setting8_label3')}} <i class=" el-icon-arrow-right"></i></p>
<!--              <p @click="goUrl(4)">{{$t('layout.aside.settingDialog.setting8_label4')}} <i class=" el-icon-arrow-right"></i></p>-->
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog class="feedbackDialog" :title="$t('layout.aside.settingDialog.feedback_title')" :visible.sync="feedbackDialog" width="800px"
               :close-on-click-modal="false">
      <div class="feedbackDialog-cont">
        <el-form ref="form" class="form" :model="feedbackForm" :hide-required-asterisk="true">
          <el-form-item :label="$t('form.label_feedback')" prop="titleContent" :rules="[{ required: true, message: $t('form.place_feedback'), trigger: 'blur' }]">
            <el-input type="textarea" :placeholder="$t('form.place_feedback')" rows="6" v-model="feedbackForm.titleContent"></el-input>
          </el-form-item>
          <div class="label">
            <div class="tit">{{$t('form.label_img')}}<span>（{{$t('form.label_img_tip')}}）</span></div>
<!--            <div class="right">0/6</div>-->
          </div>
          <div class="upload">
            <el-upload :action="uploadDomains" :show-file-list="false" :on-success="handleAvatarSuccess1">
              <img v-if="feedbackForm.titlePic" :src="feedbackForm.titlePic" style="height:100px;">
              <i v-else slot="default" class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="tip">{{$t('form.feedback_tip')}} <span>{{$t('form.online')}}</span></div>
          <div class="btn">
            <el-button :loading="loading" @click="createBack">{{$t('common.submit')}}</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {serviceUrl, uploadDomain} from "@/api/global";
import '@/utils/params'
import {createFeedback, createLanguage} from "@/api/login";
import { removeToken } from "@/utils/auth";

export default {
  props: {},
  data() {
    return {
      uploadDomains: uploadDomain,
      languageTotal: global.languageTotal,
      newsDialog: false,
      newType: 0,
      realnameDialog: false,
      settingDialog: false,
      feedbackDialog: false,
      settingTab: 0,
      languageActive: '',
      nowPath: undefined,
      autoAdd: false,
      feedbackForm: {
        titleContent: undefined,
        titlePic: undefined
      },
      loading: false,
      theme: "2",
      themeColor: "1",
      chart: "1",
    };
  },
  created() {
    this.nowPath = this.$route.path
    for (var i = 0; i < this.languageTotal.length; i++) {
      if (window.localStorage.getItem("lang") === this.languageTotal[i].abbr) {
        this.languageActive = this.languageTotal[i].abbr
      }
    }
  },
  methods: {
    selectLanguage(item){
      this.languageActive = item;
      this.$i18n.locale = item;
      window.localStorage.setItem(
          "lang",
          this.$i18n.locale
      );
      createLanguage({id: 1, paramType: 12, paramValue: item }).then((response) => {
        console.log(response)
      })
    },
    createBack(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          createFeedback(this.feedbackForm).then((response) => {
            this.loading = false
            this.$message.success(this.$t('common.complete'))
            this.feedbackDialog = false
          }).catch((response) => {
            this.loading = false;
          });
        } else {
          console.log("error submit!!");
          this.loading = false
          return false;
        }
      })
    },
    goUrl(number){
      let endUrl = ""
      let language = window.localStorage.getItem("lang")
      // if(language === "zh" || language === "tc"){
      //   if(number === 1){
      //     endUrl = "https://www.Richard.com/docs/Richard_PrivacyPolicy-zh-CN-CIMA.pdf"
      //   }else if(number === 2){
      //     endUrl = "https://www.Richard.com/docs/Richardclu_ProductDisclosureStatement-zh-CN-CIMA.pdf"
      //   }else if(number === 3){
      //     endUrl = "https://www.Richard.com/docs/Richardclu_RiskDisclosureStatement-zh-CN-CIMA.pdf"
      //   }else if(number === 4){
      //     endUrl = "https://www.Richard.com/docs/Richardclu_ClientAgreement-zh-CN-CIMA.pdf"
      //   }
      // }else{
        if(number === 1){
          endUrl = "https://www.Richard.com/docs/Richard_PrivacyPolicy-en-US-CIMA.pdf"
        }else if(number === 2){
          endUrl = "https://www.Richard.com/docs/Richard_ProductDisclosureStatement-en-US-CIMA.pdf"
        }else if(number === 3){
          endUrl = "https://www.Richard.com/docs/Richard_RiskDisclosureStatement-en-US-CIMA.pdf"
        }else if(number === 4){
          endUrl = "https://www.Richard.com/docs/Richard_ClientAgreement-en-US-CIMA.pdf"
        }
      // }
      window.open(endUrl)
    },
    jumpUrl() {
      window.open(serviceUrl)
    },
    handleAvatarSuccess1(res, file) {
      this.feedbackForm.titlePic = res.Data.key
    },
    async logout() {
      await this.$confirm(this.$t("message.logout"), this.$t("common.tip"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('user/logout')
        removeToken()
        this.$router.push(`/`)
      })
    },
  },
}
</script>

<style lang="less" scoped>
.aside {
  width: 72px;
  box-sizing: border-box;
  background: var(--background-w8);
  height: 100%;
  border-right: 4px solid var(--background-w1);

  .tac {
    height: 100%;
    width: 100%;
    display: flex;
    background: var(--background-w2);
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    .top,
    .bottom {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      ul {
        li {
          height: 64px;
          cursor: pointer;
          text-align: center;
          color: #000000FF;

          i {
            font-size: 22px;
          }

          &.active {
            color: var(--el-menu-active-color);
          }

          p {
            font-size: 12px;
            padding-top: 6px;
          }
        }
      }
    }

    .bottom {
      padding-bottom: 40px;

      ul {
        li {
          height: 44px;
        }
      }
    }
  }
}

/deep/ .el-dialog {
  border-radius: 10px;

  .el-dialog__header {
    background-color: var(--background-w1);
    padding: 16px;
    margin-right: 0;
    border-radius: 10px 10px 0 0;
    text-align: center;

    .el-dialog__title {
      font-size: var(--el-font-size-medium);
    }
  }

  .el-dialog__body {
    padding: 10px 20px;
  }

  .newsDialog-cont {
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--background-w6);

      .left {
        display: flex;
        align-items: center;

        .item {
          margin: 0 10px;
          padding: 6px 0;
          cursor: pointer;

          span {
            padding: 6px 0;
          }

          .active {
            color: var(--el-color-primary);
            border-bottom: 1px solid var(--el-color-primary);
          }
        }
      }

      .right {
        color: var(--el-color-primary);
        cursor: pointer;
        font-size: 12px;
      }

    }

    .list {
      .box {
        // max-height: 600px;
        height: 600px;

        ul {
          li {
            position: relative;
            display: flex;
            padding: 10px 12px;
            border-bottom: 1px solid var(--background-w6);

            .type {
              margin-top: 5px;
              margin-right: 10px;
            }

            .new-main {
              font-size: 12px;
              padding-left: 5px;

              .tit {
                line-height: 20px;
                font-weight: 700;
                display: flex;
                justify-content: space-between;
                color: var(--el-text-color-primary);
              }

              .time {
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}

/deep/ .settingDialog {
  .el-dialog__body {
    height: 670px;
    padding: 0;
  }

  .settingDialog-cont {
    height: 100%;
    display: flex;

    .tabs {
      padding: 0 32px;
      width: 250px;
      border-right: 1px solid var(--background-w6);
      height: 100%;
      padding-top: 40px;
      position: relative;

      .tab {
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;

        .iconfont {
          margin-right: 10px;

        }

        &.active {
          background: var(--background-w3);
          border-radius: 4px;
          color: var(--el-color-primary);
        }
      }

      .logout {
        position: absolute;
        bottom: 32px;
        left: 32px;
        font-size: 14px;
      }
    }

    .setting-cont {
      flex: 1;
      padding-top: 40px;
      padding-left: 50px;
      padding-right: 60px;

      .item {
        padding-bottom: 20px;

        .title {
          padding-bottom: 20px;
          font-size: var(--el-font-size-large);
          font-weight: var(--cc-text-font-weight);
        }

        .el-select {
          width: 100%;

          .el-input__inner {
            background: var(--background-w3);
            border: none;
          }
        }

        p {
          display: flex;
          justify-content: space-between;
          height: 54px;
          align-items: center;
          font-size: var(--el-font-size-medium);

          i {
            font-weight: bold;
          }
        }

        .list {
          p {
            border-bottom: 1px solid var(--background-w6);
          }
        }

        .radio-group {
          .el-radio__inner {
            border-radius: 0;
          }

          .el-radio__input.is-checked {
            .el-radio__inner::after {
              content: '';
              width: 8px;
              height: 3px;
              border: 2px solid white;
              border-top: transparent;
              border-right: transparent;
              text-align: center;
              display: block;
              position: absolute;
              top: 2px;
              left: 1px;
              vertical-align: middle;
              transform: rotate(-45deg);
              border-radius: 0px;
              background: none;
            }
          }
        }


      }
    }
  }
}

/deep/ .feedbackDialog {
  .el-dialog__body {
    height: 670px;
    padding: 0;
  }

  .feedbackDialog-cont {
    height: 100%;
    max-width: 430px;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    .form {
      .el-textarea__inner {
        background-color: var(--background-w3);
        color: var(--font-icon-T1);
      }

      .label {
        display: flex;
        justify-content: space-between;

        .right {
          font-size: 12px;
        }
      }

      .upload {
        margin-top: 20px;
        background-color: var(--background-w3);
        padding: 16px;

        .el-upload--picture-card {
          width: 88px;
          height: 88px;
          line-height: 88px;
          border: none;
        }

        .el-upload-list--picture-card {
          .el-upload-list__item {
            width: 88px;
            height: 88px;
            line-height: 88px;
          }
        }
      }

      .tip {
        text-align: center;
        margin-top: 50px;

        span {
          color: var(--color-b1);
        }
      }

      .btn {
        .el-button {
          width: 100%;
          color: #FFFFFF;
          background: var(--color-b1);
        }
      }

    }
  }
}
</style>
